import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { Button, httpPost, User, AuthContext } from "@streamy/common";

interface PasswordFormData {
    password: string;
    confirmPassword: string;
    email: string;
}

interface AuthResponse {
    accessToken: string;
    refreshToken: string;
    user: User;
}

interface ResetResponse extends AuthResponse {
    status: string;
    tokens: {
        accessToken: string;
        refreshToken: string;
    };
    user: User;
}

export const ResetPassword = () => {
    const { login } = useContext(AuthContext);
    const { register, handleSubmit, watch, errors, setValue } = useForm<PasswordFormData>();
    const [resetStatus, setResetStatus] = useState<"pending" | "resolved" | "rejected">("pending");
    const [requestStatus, setRequestStatus] = useState<"pending" | "resolved" | "rejected">("pending");
    const [emailInput, setEmailInput] = useState("");

    const { search } = useLocation();
    const searchParams = useMemo(() => {
        const s = new URLSearchParams(search);
        return {
            email: s.get("email"),
            token: s.get("token"),
        };
    }, [search]);

    useEffect(() => {
        if (searchParams.email) {
            setValue("email", searchParams.email);
        }
    }, [searchParams, setValue]);

    const onSubmit = (formData: PasswordFormData) => {
        if (!searchParams.token) {
            return;
        }

        httpPost<ResetResponse>(
            "users/change-password-confirm",
            JSON.stringify({
                ...formData,
                confirmationToken: searchParams.token,
            })
        )
            .then((d) => {
                setResetStatus("resolved");
                login(d.tokens.accessToken, d.tokens.refreshToken, d.user);
            })
            .catch((e) => {
                console.log(e);
                console.log("rejected confirmation");
                setRequestStatus("rejected");
            });
    };

    const requestTokenEmail = (event: any) => {
        event.preventDefault();
        httpPost(
            "users/change-password-request",
            JSON.stringify({
                email: emailInput,
            })
        )
            .then((d) => {
                setRequestStatus("resolved");
                setEmailInput("");
            })
            .catch((e) => {
                setRequestStatus("rejected");
            });
    };

    if (!searchParams.email && !searchParams.token) {
        return (
            <div className="mobile:w-full">
                <form onSubmit={requestTokenEmail} inputMode="email">
                    <label className="block pb-6" htmlFor="email" inputMode="email">
                        Reset password – confirm your email
                    </label>

                    <input
                        id="email"
                        type="email"
                        className="w-full border-gray-300 border rounded p-1 text-xl"
                        autoComplete="email"
                        value={emailInput}
                        onChange={(e) => {
                            setEmailInput(e.target.value);
                        }}
                    />
                    {requestStatus === "resolved" && (
                        <div
                            className="laptop:max-w-xl flex items-center text-xl px-4 py-6 bg-green-300 mt-3"
                            role="alert"
                        >
                            <p>
                                If an account registered with this e-email address exists, an e-mail will be sent with a
                                link to reset password.
                                <br/>
                                <Link className="text-blue-700 hover:text-blue-500 underline" to="/min-konto">
                                    Back to login page
                                </Link>
                            </p>
                        </div>
                    )}
                    <Button onClick={requestTokenEmail} className="mt-4">
                        Reset password
                    </Button>
                </form>
                <p className={"py-6 text-md"}>
                    This will send a link to your email with instructions on how to reset your password. If you do not receive an email check your spam filter and check that you input the correct email address.
                </p>
            </div>
        );
    }

    if (resetStatus === "resolved") {
        return (
            <div className="flex items-center text-xl px-4 py-20" role="alert">
            <p>
                    Password was reset. <br />
                    <Link className="text-blue-700 hover:text-blue-500 underline" to="/min-konto">
                        Log in
                    </Link>
                </p>
            </div>
        );
    }

    return (
        <>
            <h1 className="text-6xl px-4 py-12 w-full">Reset password</h1>
            <form onSubmit={handleSubmit(onSubmit)} className="max-w-2xl px-4 pb-10">
                <input type="text" name="email" ref={register()} autoComplete="email" hidden />
                <fieldset className="pb-4">
                    <label className="block" htmlFor="register-password">
                        New password
                    </label>
                    <input
                        id="register-password"
                        type="password"
                        className="w-full border-gray-300 border rounded p-1 text-xl"
                        name="password"
                        autoComplete="new-password"
                        ref={register({
                            required: "Field is required",
                        })}
                    />
                    {errors.password && (
                        <span role="alert" className="text-red-600">
                            {errors.password.message}
                        </span>
                    )}
                </fieldset>
                <fieldset>
                    <label className="block" htmlFor="confirm-password">
                        Confirm new password
                    </label>
                    <input
                        id="confirm-password"
                        type="password"
                        className="w-full border-gray-300 border rounded p-1 text-xl"
                        name="confirmPassword"
                        autoComplete="new-password"
                        ref={register({
                            required: "Field is required",
                            validate: (value) =>
                                value === watch("password") || "Confirmed password does not match new password.",
                        })}
                    />
                    {errors.confirmPassword && (
                        <span role="alert" className="text-red-600">
                            {errors.confirmPassword.message}
                        </span>
                    )}
                </fieldset>
                {resetStatus === "rejected" && (
                    <div className="w-full p-2 text-lg text-white bg-red-500 rounded-sm mt-6">
                        Resetting password failed. Please check the details in the email.
                    </div>
                )}
                <Button className="mt-4">Submit</Button>
            </form>
        </>
    );
};
