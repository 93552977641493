import React from "react";
import { View } from "@streamy/common";

const WantToStreamPage = () => {
    return (
        <View>
            <div className="flex max-w-6xl flex-col w-full mx-auto pb-10">
                <h1 className="text-6xl">Want to stream on Streamy?</h1>

                We’re here to help!<br />
                📩 Contact us at kontakt@streamy.no

            </div>
        </View>
    );
};

export default WantToStreamPage;
